import throttle from 'lodash.throttle'
import { useRouter } from 'next/router'
import { RefObject, useCallback, useEffect, useMemo, useRef } from 'react'

import { useCurrentUserContext } from '@context/CurrentUserContext'
import { countRemainingUrls, findNextUrlKey, findPreviousItem } from '@lib/urlUtils'
import userApi from '@redux/api/userApi'
import { decrementViewsLeftForNSFWPrompt } from '@redux/slices/appSlice'
import {
  feedConstants,
  incrementPage,
  selectCurrentFeed,
  selectCurrentFeedStatus,
  selectNextItem,
  setCurrentFeedItemKey,
  setPageSize,
} from '@redux/slices/feedSlice'
import { setIsInterstitialModalOpen } from '@redux/slices/modals/interstitialModalSlice'
import { setIsLoginModalOpen, setIsLoginPromptOpen } from '@redux/slices/modals/loginModalSlice'
import { selectUserId } from '@redux/slices/userSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { useSegmentTrack } from './analytics/useSegmentAnalytics'
import { useDeeplinkInfo } from './useDeeplinkInfo'

const WHEEL_THROTTLE_WAIT = 2000

export const useWheelHandler = (feedRef: RefObject<HTMLDivElement>) => {
  const { handlePreviousNavigation, handleNextNavigation } = useFeedNavigation()
  const goToNextItemRef = useRef(handleNextNavigation)
  const goToPreviousItemRef = useRef(handlePreviousNavigation)
  const { isLoginHeroOpen } = useAppSelector(state => state.loginModal)

  useEffect(() => {
    goToNextItemRef.current = handleNextNavigation
  }, [handleNextNavigation])
  useEffect(() => {
    goToPreviousItemRef.current = handlePreviousNavigation
  }, [handlePreviousNavigation])

  const wheelHandler = useMemo(
    () =>
      throttle(
        (event: WheelEvent) => {
          if (event.deltaY < 0) return goToPreviousItemRef.current()
          if (event.deltaY > 0) return goToNextItemRef.current()
        },
        WHEEL_THROTTLE_WAIT,
        { trailing: false }
      ),
    [goToNextItemRef, goToPreviousItemRef]
  )

  useEffect(() => {
    const feedWrapper = feedRef.current
    feedWrapper?.removeEventListener('wheel', wheelHandler)

    if (!feedWrapper || isLoginHeroOpen) {
      return
    }

    const timeoutId = setTimeout(() => {
      feedWrapper.addEventListener('wheel', wheelHandler)
    }, WHEEL_THROTTLE_WAIT)

    return () => {
      clearTimeout(timeoutId)
      feedWrapper.removeEventListener('wheel', wheelHandler)
    }
  }, [feedRef, wheelHandler, isLoginHeroOpen])
}

const useFeedNavigation = () => {
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { isLoggedIn } = useCurrentUserContext()
  const track = useSegmentTrack()
  const deeplinkInfo = useDeeplinkInfo()

  const { isLoginModalOpen, isLoginHeroOpen } = useAppSelector(state => state.loginModal)
  const { identifier, currentFeedItemKey } = useAppSelector(state => state.feed)
  const userId = useAppSelector(selectUserId)
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const username = user?.username
  const currentFeed = useAppSelector(selectCurrentFeed)
  const currentFeedStatus = useAppSelector(selectCurrentFeedStatus)

  const nextItem = useAppSelector(selectNextItem)

  const showModalOrFollowLink = useCallback(() => {
    if (deeplinkInfo?.deeplinkUrl) {
      track('app-download', { platform: deeplinkInfo.platform })
      location.assign(deeplinkInfo.deeplinkUrl)
      return
    }
    dispatch(setIsLoginModalOpen(true))
    return
  }, [deeplinkInfo?.deeplinkUrl, deeplinkInfo?.platform, track, dispatch])

  useEffect(() => {
    if (nextItem?.type === 'url') router.prefetch(`/!${nextItem.url_id}`).catch(() => {})
  }, [nextItem, router])

  const handlePreviousNavigation = useCallback(() => {
    if (isLoginModalOpen || isLoginHeroOpen) return
    const prevItem = findPreviousItem(currentFeed?.items, currentFeedItemKey)
    if (!prevItem) return

    if (prevItem?.type === 'web_interstitial') {
      dispatch(setCurrentFeedItemKey(prevItem.id))
      dispatch(setIsInterstitialModalOpen(true))
      return
    }

    if (prevItem?.type === 'login') {
      dispatch(setCurrentFeedItemKey(prevItem.id))
      dispatch(setIsLoginPromptOpen(true))
      return
    }

    let queryString = `?skipSSR=true`
    let asPath = `/!${prevItem.url_id}`

    if (identifier.slug === 'user') {
      queryString = queryString.concat(`&liked_by=${username}`)
      asPath = asPath.concat(`?liked_by=${username}`)
    }
    dispatch(setIsLoginPromptOpen(false))
    dispatch(setCurrentFeedItemKey(prevItem.url_id))
    return router.push(`/!${prevItem.url_id}${queryString}`, asPath, { shallow: true })
  }, [
    isLoginModalOpen,
    isLoginHeroOpen,
    router,
    identifier.slug,
    currentFeed?.items,
    currentFeedItemKey,
    username,
    dispatch,
  ])

  const handleNextNavigation = useCallback(() => {
    if (isLoginModalOpen || isLoginHeroOpen) return
    const isNextItemLoginPrompt = nextItem?.type === 'login'
    const remainingUrlsInPage = countRemainingUrls(currentFeed?.items ?? [], currentFeedItemKey)
    if (currentFeedStatus !== 'pending' && remainingUrlsInPage <= feedConstants.LOAD_MORE_THRESHOLD) {
      dispatch(incrementPage(1))
      dispatch(setPageSize(feedConstants.FULL_SCREEN_PAGE_SIZE))
    }

    if (nextItem?.type === 'web_interstitial') {
      dispatch(setCurrentFeedItemKey(nextItem.id))
      dispatch(setIsInterstitialModalOpen(true))
      return
    }

    if (nextItem?.type === 'login') {
      dispatch(setCurrentFeedItemKey(nextItem.id))
      dispatch(setIsLoginPromptOpen(true))
      return
    }

    dispatch(decrementViewsLeftForNSFWPrompt())

    if (isLoggedIn || !isNextItemLoginPrompt) {
      dispatch(setIsLoginPromptOpen(false))
      const nextUrlKey = findNextUrlKey(currentFeed?.items, currentFeedItemKey)
      if (!nextUrlKey) return

      let queryString = `?skipSSR=true`
      let asPath = `/!${nextUrlKey}`

      if (identifier.slug === 'user') {
        queryString = `?skipSSR=true&liked_by=${username}`
        asPath = `/!${nextUrlKey}?liked_by=${username}`
      }
      const href = `/!${nextUrlKey}${queryString}`
      dispatch(setCurrentFeedItemKey(nextUrlKey))
      return router.push(href, asPath, { shallow: true })
    }
    showModalOrFollowLink()
  }, [
    currentFeedStatus,
    isLoggedIn,
    nextItem,
    showModalOrFollowLink,
    router,
    identifier.slug,
    username,
    dispatch,
    currentFeedItemKey,
    currentFeed?.items,
    isLoginModalOpen,
    isLoginHeroOpen,
  ])

  return { handlePreviousNavigation, handleNextNavigation }
}

export default useFeedNavigation
