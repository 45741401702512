import { HYDRATE } from 'next-redux-wrapper'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppState } from '../../store/store'

export interface LoginModalSlice {
  isLoginModalOpen: boolean
  isLoginPromptOpen: boolean
  isLoginHeroOpen: boolean
  copyVariant: 'default' | 'add' | 'login' | 'personalize' | 'conversation'
  loginModalInnerUI: 'default' | 'email' | 'emailSubmitted'
  returnUrl?: string
  returnPathOnClose?: string
  _metadata: { updatedFields: string[] }
}

const initialState: LoginModalSlice = {
  isLoginModalOpen: false,
  isLoginPromptOpen: false,
  isLoginHeroOpen: false,
  copyVariant: 'default',
  loginModalInnerUI: 'default',
  _metadata: { updatedFields: [] },
}

const loginModalSlice = createSlice({
  name: 'loginModal',
  initialState,
  reducers: {
    setIsLoginModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginModalOpen = action.payload
      state._metadata.updatedFields.push('isLoginModalOpen')
    },
    setIsLoginPromptOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginPromptOpen = action.payload
      state._metadata.updatedFields.push('isLoginPromptOpen')
    },
    setIsLoginHeroOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginHeroOpen = action.payload
      state._metadata.updatedFields.push('isLoginHeroOpen')
    },
    setCopyVariant: (state, action: PayloadAction<'default' | 'add' | 'login' | 'personalize' | 'conversation'>) => {
      state.copyVariant = action.payload
      state._metadata.updatedFields.push('copyVariant')
    },
    setLoginModalInnerUI: (state, action: PayloadAction<'default' | 'email' | 'emailSubmitted'>) => {
      state.loginModalInnerUI = action.payload
      state._metadata.updatedFields.push('loginModalInnerUI')
    },
    setReturnUrl: (state, action: PayloadAction<string | undefined>) => {
      state.returnUrl = action.payload
      state._metadata.updatedFields.push('returnUrl')
    },
    setReturnPathOnClose: (state, action: PayloadAction<string | undefined>) => {
      state.returnPathOnClose = action.payload
      state._metadata.updatedFields.push('returnPathOnClose')
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      (action): action is PayloadAction<AppState> => action.type === HYDRATE,
      (state, action) => {
        const serverApp = action.payload.loginModal

        const fieldsToCheck: (keyof LoginModalSlice)[] = [
          'isLoginModalOpen',
          'isLoginPromptOpen',
          'isLoginHeroOpen',
          'copyVariant',
          'loginModalInnerUI',
          'returnUrl',
          'returnPathOnClose',
        ]

        function updateField<K extends keyof LoginModalSlice>(
          state: LoginModalSlice,
          field: K,
          value: LoginModalSlice[K]
        ): void {
          state[field] = value
        }

        fieldsToCheck.forEach(field => {
          if (
            serverApp._metadata.updatedFields.includes(field) &&
            serverApp[field] !== undefined &&
            serverApp[field] !== state[field]
          ) {
            updateField(state, field, serverApp[field])
          }
        })
        state._metadata.updatedFields = []
      }
    )
  },
})

export const setLoginModalState = ({
  copyVariant,
  returnUrl,
  returnPathOnClose,
  isLoginModalOpen,
  loginModalInnerUI,
  dispatch,
}: Pick<LoginModalSlice, 'copyVariant' | 'returnUrl' | 'returnPathOnClose' | 'isLoginModalOpen'> & {
  dispatch: AppDispatch
  loginModalInnerUI?: 'default' | 'email' | 'emailSubmitted'
}) => {
  dispatch(setCopyVariant(copyVariant))
  dispatch(setIsLoginModalOpen(isLoginModalOpen))
  if (returnUrl) dispatch(setReturnUrl(returnUrl))
  if (returnPathOnClose) dispatch(setReturnPathOnClose(returnPathOnClose))
  if (loginModalInnerUI) dispatch(setLoginModalInnerUI(loginModalInnerUI))
}
export const {
  setIsLoginModalOpen,
  setIsLoginPromptOpen,
  setIsLoginHeroOpen,
  setLoginModalInnerUI,
  setCopyVariant,
  setReturnUrl,
  setReturnPathOnClose,
} = loginModalSlice.actions

export default loginModalSlice.reducer
