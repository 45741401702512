import { ConnectedRouter } from 'connected-next-router'
import { ReactNode } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { SWRConfig } from 'swr'

import { BottomSheetProvider } from '@context/BottomSheetContext'
import { CommandContextProvider } from '@context/CommandContext'
import { CurrentUserContextProvider } from '@context/CurrentUserContext'
import { GlobalContextProvider } from '@context/GlobalContexts'
import { ProgressBarProvider } from '@context/ProgressBarContext'
import { SessionManagementProvider } from '@context/SessionManagementContext'
import { SessionRecordingObserver } from '@context/SessionRecordingObserver'
import { SidePanelProvider } from '@context/SidePanelContext'
import { ThemeProvider } from '@context/ThemeContext'
import { SessionRecordingProvider } from '@hooks/analytics/useRecordSession'
import { useRequest } from '@hooks/useRequest'
import { BrowserInfo } from '@lib/browser'
import { commandContextSWRMiddleware } from '@lib/commands/commandContextSWRMiddleware'
import { abcGravity, gravityCompressed, gravityExtraCondensed, gtWalsheim, inter, sharpSans } from '@lib/fonts'
import runtimeConfig from '@lib/RuntimeConfig'
import InternalServerErrorPage from '@pages/500.page'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { AppStore } from '@redux/store/store'
import IntoAPI from '@services/IntoAPI'
import { MessageServiceProvider } from '@tracking/context/MessageServiceContext'
import ErrorBoundary from './ErrorBoundary'

interface AppProvidersProps {
  children: ReactNode
  store: AppStore
  browserInfo: BrowserInfo
  isLoggedIn: boolean
  pageName?: string
}

export const AppProviders = ({ children, store, browserInfo, isLoggedIn, pageName }: AppProvidersProps) => {
  const { data: intoConfig } = useRequest(isLoggedIn ? IntoAPI.getIntoConfig() : {})

  return (
    <>
      <style jsx global>{`
        :root {
          --font-sharp-sans: ${sharpSans.style.fontFamily};
          --font-gt-walsheim: ${gtWalsheim.style.fontFamily};
          --font-gravity-compressed: ${gravityCompressed.style.fontFamily};
          --font-gravity-extra-condensed: ${gravityExtraCondensed.style.fontFamily};
          --font-abc-gravity: ${abcGravity.style.fontFamily};
          --font-inter: ${inter.style.fontFamily};
        }

        html {
          font-family: ${inter.style.fontFamily};
          overflow-x: hidden;
        }
      `}</style>
      <main
        className={`${gtWalsheim.variable} ${sharpSans.variable} ${gravityCompressed.variable} ${gravityExtraCondensed.variable} ${abcGravity.variable} ${inter.variable}`}
      >
        <ThemeProvider>
          <ReduxProvider store={store}>
            <ConnectedRouter>
              <GlobalContextProvider browserInfo={browserInfo} pageName={pageName} intoConfig={intoConfig}>
                <CommandContextProvider>
                  <SessionManagementProvider>
                    <CurrentUserContextProvider isLoggedInFromCookies={isLoggedIn}>
                      <SWRConfig value={{ use: [commandContextSWRMiddleware] }}>
                        <GoogleOAuthProvider clientId={runtimeConfig().publicRuntimeConfig.auth.google.clientID}>
                          <MessageServiceProvider>
                            <ErrorBoundary fallbackComponent={<InternalServerErrorPage disableTracking={false} />}>
                              <SessionRecordingProvider>
                                <SidePanelProvider>
                                  <BottomSheetProvider>
                                    <ProgressBarProvider>
                                      <SessionRecordingObserver />
                                      {children}
                                    </ProgressBarProvider>
                                  </BottomSheetProvider>
                                </SidePanelProvider>
                              </SessionRecordingProvider>
                            </ErrorBoundary>
                          </MessageServiceProvider>
                        </GoogleOAuthProvider>
                      </SWRConfig>
                    </CurrentUserContextProvider>
                  </SessionManagementProvider>
                </CommandContextProvider>
              </GlobalContextProvider>
            </ConnectedRouter>
          </ReduxProvider>
        </ThemeProvider>
      </main>
    </>
  )
}
