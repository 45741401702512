import { ParsedUrlQuery } from 'querystring'
import { AxiosHeaders, AxiosRequestConfig } from 'axios'

import runtimeConfig from '@lib/RuntimeConfig'
import { IntoNode } from '@models/IntoNode'
import { IntoUrl } from '@models/IntoUrl'
import { IntoUser } from '@models/IntoUser'
import { NotificationsResponse } from '@models/NotificationsResponse'
import { RecommendFilter, RecommendRequest } from '@models/RecommendRequest'
import RecommendResponse from '@models/RecommendResponse'
import { TimeCapsuleResponse } from '@models/TimeCapsuleResponse'

import './MixAxios'

import { APIRequest } from '@hooks/useRequest'

const NOTIFICATIONS_PAGE_LIMIT = 10

const IntoAPIConfig = <Request>(requestConfig: AxiosRequestConfig<Request>): AxiosRequestConfig<Request> => ({
  baseURL: `${runtimeConfig().publicRuntimeConfig.into.baseUrl}/api`,
  headers: new AxiosHeaders({
    'Content-Type': 'application/json',
    'x-mix-client': 'com.mix.web',
  }),
  withCredentials: true,
  ...requestConfig,
})

export interface IntoConfig {
  displayOptions?: {
    showComments?: boolean
  }
}

export interface IntoUrlSummaryDetail {
  icon: string
  info: string
  label: string
}

export interface IntoUrlSummary {
  blocked: boolean
  details: IntoUrlSummaryDetail[]
  quickSummary: string
}

export interface IntoUrlSummaryResponse {
  summary: IntoUrlSummary
}

export interface IntoUrlResponse {
  url: IntoUrl[]
}

export interface IntoUserRequest {
  user_id: number
}

export interface IntoPaginatedRequest {
  page: number
}

export interface IntoUserPaginatedRequest extends IntoUserRequest, IntoPaginatedRequest {}

// Request to get paginated items for a user and given key to identify the request type.
// This is helpful when the request payload gets used as SWR key without the PATH (e.g., `useRequestInfinite`)
export interface IntoUserPaginatedRequestWithKey extends IntoUserPaginatedRequest {
  keyId: string
}

export interface RealtimeClientEvent {
  rec_batch_id: string
  user_id: number
  item_type: string
  item_entity_id: string
  item_user_id: number
  item_url_id: number
  user_activity_event_name: string
  event_type: string
  event_type_id: number
  event_group: string
  event_group_id: number
  event_state: number
  app_type: string
  app_build_number: string
  app_version: string
  device: string
  page_type: string
  reference_page_type: string
  reference_stream_type: string
  stream_type: string
  stream_id: string
  perceived_media_type: string
  event_context: null | string
  user_role: string
  event_stamp: number
  event_day: string
  session_id: string
  stream_url_id: null | string
  created_at: number
  server_stamp: number
  client_session_id: string
  device_uuid: string
  id: string
}

export interface RealtimeClientEventsResponse {
  data: RealtimeClientEvent[]
  card: string
}

const IntoAPI = {
  onboarding: {
    getOnboardingTopics: (): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: `/onboarding/nodes-nested`,
      }),
  },
  notifications: {
    getUnreadNotificationCount: (): APIRequest<{
      activities: number
      notifications: number
      shares: number
    }> =>
      IntoAPIConfig({
        method: 'GET',
        url: '/unread/meta',
      }),
    getNotifications: (page: number): APIRequest<NotificationsResponse> =>
      IntoAPIConfig({
        method: 'GET',
        url: `/notifications?page=${page}&limit=${NOTIFICATIONS_PAGE_LIMIT}`,
      }),
    markNotificationRead: (notificationID: string): APIRequest<NotificationsResponse> =>
      IntoAPIConfig({
        method: 'PUT',
        url: `/notification/${notificationID}/read`,
      }),
    markAllNotificationsRead: () =>
      IntoAPIConfig({
        method: 'POST',
        url: `/notifications/read`,
      }),
  },
  discover: {
    getFeed: (): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/discover',
        data: {},
      }),
  },
  url: {
    getDetails: (data: { url_id: string; query?: ParsedUrlQuery }): APIRequest<IntoUrlResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/details',
        data,
      }),
    getDetailsByHash: (data: { url_hash: string }): APIRequest<IntoUrlResponse, { url_hash: string }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/details',
        data,
      }),
    getSummary: (data: { url_id: string }): APIRequest<IntoUrlSummaryResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/details/summary',
        data,
      }),
    likers: (data: { url_id: string }): APIRequest<IntoUser[], { url_id: string }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/into/users',
        data,
      }),
    save: (data: { url_id: string }): APIRequest<IntoUrl> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/save',
        data,
      }),
    deleteSaved: (data: { url_id: string }): APIRequest<IntoUrl> =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/url/save',
        data,
      }),
    markSeen: (data: { url_id: string }): APIRequest =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/seen',
        data,
      }),
    setInto: (data: { url_id: string }): APIRequest =>
      IntoAPIConfig({
        method: 'POST',
        url: '/url/into',
        data,
      }),
    deleteInto: (data: { url_id: string }): APIRequest =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/url/into',
        data,
      }),
    getCounts: (data: { url_id: string }): APIRequest<{ comments: number | undefined }> =>
      IntoAPIConfig({
        method: 'GET',
        url: `/url/${data.url_id}/counts`,
      }),
    likeComment: ({ commentID }: { commentID: string }): APIRequest<{ comments: number | undefined }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/comments/like',
        data: { comment_id: commentID },
      }),
    unlikeComment: ({ commentID }: { commentID: string }): APIRequest<{ comments: number | undefined }> =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/comments/like',
        data: { comment_id: commentID },
      }),
  },
  user: {
    getCurrentUserDetails: (): APIRequest<IntoUser> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/user/details',
      }),
    getDetails: (
      data:
        | {
            username: string
          }
        | {
            user_id: number
          }
    ): APIRequest<IntoUser> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/user/details',
        data,
      }),
    getUrls: (data: IntoUserPaginatedRequest): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/user/urls',
        data,
      }),
    getPosts: (data: IntoUserPaginatedRequest): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/user/curated',
        data,
      }),
    follow: (data: IntoUserRequest): APIRequest<IntoUser> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/user/like',
        data,
      }),
    unfollow: (data: IntoUserRequest): APIRequest<IntoUser> =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/user/like',
        data,
      }),
    getLikes: (data: IntoUserPaginatedRequestWithKey): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/user/likes',
        data,
      }),
    getLikers: (data: IntoUserPaginatedRequestWithKey): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/user/likers',
        data,
      }),
    search: (data: { query: string }): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/users/search',
        data,
      }),
    recommendedLikers: (data: { url_id: string }): APIRequest<RecommendResponse, { url_id: string }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/user/recommended-likers',
        data,
      }),
    getTimeCapsule: (data?: { user_id?: number }): APIRequest<TimeCapsuleResponse> =>
      IntoAPIConfig({
        method: 'GET',
        url: '/user/time-capsule',
        params: data,
      }),
  },
  share: {
    markShareReactionRead: (shareID: number): APIRequest<NotificationsResponse> =>
      IntoAPIConfig({
        method: 'PUT',
        url: `share/${shareID}/reactions/read`,
      }),
  },
  node: {
    getDetails: (slug: string): APIRequest<IntoNode, { slug: string }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/node/details',
        data: { slug },
      }),
    getFollowers: (slug: string): APIRequest<RecommendResponse, { slug: string }> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/node/followers',
        data: { slug },
      }),
    getOnboardingNodes: (): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/onboarding/nodes',
      }),
    getFollowedNodes: (data: { page: number }): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/node/followed',
        data,
      }),
    follow: (data: { slug: string }): APIRequest<IntoNode> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/node/follow',
        data,
      }),
    unfollow: (data: { slug: string }): APIRequest<IntoNode> =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/node/follow',
        data,
      }),
    block: (data: { slug: string }): APIRequest<IntoNode> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/node/block',
        data,
      }),
    unblock: (data: { slug: string }): APIRequest<IntoNode> =>
      IntoAPIConfig({
        method: 'DELETE',
        url: '/node/block',
        data,
      }),
    search: (data: { query: string }): APIRequest<RecommendResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/nodes/search',
        data,
      }),
  },
  recommend: {
    slug: <T extends RecommendFilter>(data: RecommendRequest<T>): APIRequest<RecommendResponse, RecommendRequest<T>> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/recommend/slug',
        data,
      }),
    command: (data: { command: string; message: unknown }) =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/recommend/command',
        data,
      }),
    filter: (data: object) =>
      IntoAPIConfig({
        method: 'POST',
        url: '/v1/recommend',
        data,
      }),
  },
  getIntoConfig: () =>
    IntoAPIConfig({
      method: 'GET',
      url: '/config',
    }),
  admin: {
    getRealtimeClientEvents: (data: {
      url_id: string
      user_id: number
      card_only?: boolean
      event_timestamp?: number
    }): APIRequest<RealtimeClientEventsResponse> =>
      IntoAPIConfig({
        method: 'POST',
        url: '/admin/realtime_client_events',
        data,
      }),
  },
}

export default IntoAPI
