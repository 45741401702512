import axios from 'axios'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import { FollowButton } from '@common/FollowButton'
import Modal from '@common/Modal'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useTrackClick } from '@hooks/analytics/useTrackClick'
import IconNo from '@icons/IconNo.svg'
import UrlHelper from '@lib/UrlHelper'
import urlHelper from '@lib/UrlHelper'
import { errorToHelpfulMessage } from '@models/APIErrorResponse'
import { useToggleUserFollow } from '@pages/user/hooks/useToggleUserFollow'
import userApi from '@redux/api/userApi'
import { useAppSelector } from '@redux/store/store'
import MixMix from '@services/MixMix'

const UserSidePanel = ({ userId }: { userId: number | undefined }) => {
  const { currentUserID, isLoggedIn, currentUser } = useCurrentUserContext()
  const identifier = useAppSelector(state => state.feed.identifier)
  const router = useRouter()
  const { data: user } = userApi.useGetUserDetailsQuery({ userId: userId ?? 0 }, { skip: !userId })
  const [isOwnProfile, setIsOwnProfile] = useState(false)
  const [isCurrentUserEmployee, setIsCurrentUserEmployee] = useState(false)
  const trackClick = useTrackClick()
  const handleFollow = useToggleUserFollow(user)
  const [isBanModalVisible, setBanModalVisible] = useState(false)

  useEffect(() => {
    setIsOwnProfile(currentUserID === user?.user_id)
    setIsCurrentUserEmployee(currentUser?.isEmployee ?? false)
  }, [currentUserID, user?.user_id, currentUser?.isEmployee])

  const banUser = useCallback(
    async (removeAccess = false) => {
      await Promise.all([
        axios.request(MixMix.admin.user.makeSpammer(user?.user_id ?? 0)),
        removeAccess ? axios.request(MixMix.admin.user.removeAccess(user?.user_id ?? 0)) : Promise.resolve(),
      ])
        .then(() => toast.success('User restricted'))
        .catch(e => toast.error(errorToHelpfulMessage(e)))

      setBanModalVisible(false)
    },
    [user?.user_id]
  )

  if (!user) return <></>
  return (
    <>
      <div className="flex flex-1 flex-col items-start lg:h-full">
        <div className="flex space-x-10 text-primary sm:flex-col sm:space-x-0 sm:space-y-4">
          <Image
            className="block aspect-square size-32 shrink-0 rounded-full object-contain md:size-40"
            src={user.photo_url ?? ''}
            alt={user.display_name}
            width={400}
            height={400}
          />
          <div className="flex flex-col self-center sm:flex-col sm:items-start sm:space-y-2 sm:self-auto">
            <p className="font-sans text-3xl font-bold text-primary lg:text-4xl">{user.display_name}</p>
            <p className="text-primary opacity-50 lg:text-2xl">{`@${user.username}`}</p>
            {user?.bio?.length ? <p className="mt-4 text-primary opacity-75 sm:mt-0">{user.bio}</p> : <></>}
          </div>
        </div>
        <div className="my-8 flex w-full items-center justify-between gap-4">
          {isOwnProfile ? (
            <Link
              href={'/account'}
              onClick={() => trackClick('userSettings')}
              className="btn btn-primary btn-dot bg-white font-semibold text-black"
            >
              Settings
            </Link>
          ) : identifier.slug === 'user' ? (
            <>
              <FollowButton className="btn-dot" isFollowing={user.meta.isLiked ?? false} onFollow={handleFollow} />
              {isCurrentUserEmployee && (
                <>
                  <button
                    className="btn my-8 rounded-full p-2 font-semibold text-red-800"
                    onClick={() => setBanModalVisible(true)}
                  >
                    <IconNo className="size-6" />
                  </button>
                  <Modal isVisible={isBanModalVisible} onClose={() => setBanModalVisible(false)}>
                    <div className="z-10 flex flex-col space-y-3 rounded bg-menu p-6 text-menu">
                      <div className="text-2xl font-semibold">
                        Restrict {user.display_name} (@{user.username})?
                      </div>
                      <div className="flex justify-center space-x-6 pt-6">
                        <button className="btn bg-primary/10" onClick={() => setBanModalVisible(false)}>
                          Cancel
                        </button>
                        <button onClick={() => banUser(false)} className="btn btn-primary bg-amber-200">
                          Spammer
                        </button>
                        <button onClick={() => banUser(true)} className="btn btn-primary bg-red-500 text-white">
                          Spammer and Remove Access
                        </button>
                      </div>
                    </div>
                  </Modal>
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="flex self-stretch font-medium sm:flex-col">
          <StatLink
            href={UrlHelper.userPath(user.username)}
            count={user?.meta.likesCount}
            label="Likes"
            isActive={router.asPath === urlHelper.userPath(user.username)}
          />
          {isLoggedIn && (
            <StatLink
              href={urlHelper.userSubPath(user.username, 'posts')}
              count={user.meta.curatedCount}
              label="Posts"
              isActive={router.asPath === urlHelper.userSubPath(user.username, 'posts')}
            />
          )}
          <StatLink
            href={urlHelper.userSubPath(user.username, 'following')}
            count={user.meta.followingCount}
            label="Following"
            isActive={router.asPath === urlHelper.userSubPath(user.username, 'following')}
          />
          {isOwnProfile && (
            <>
              <StatLink
                href={urlHelper.userSubPath(user.username, 'followers')}
                count={user.meta.followersCount}
                label="Followers"
                isActive={router.asPath === urlHelper.userSubPath(user.username, 'followers')}
              />
              <StatLink
                href={urlHelper.userSubPath(user.username, 'saved')}
                count={user.meta.savedCount}
                label="Saved"
                isActive={router.asPath === urlHelper.userSubPath(user.username, 'saved')}
              />
            </>
          )}
        </div>
      </div>
    </>
  )
}

const StatLink = ({
  href,
  count,
  label,
  isActive,
}: {
  href: string
  count?: number
  label: string
  isActive: boolean
}) => (
  <Link
    as={href}
    href={`${href}/?skipUserSSR=true`}
    className={`tab relative flex flex-1 flex-col items-center justify-between p-2 sm:flex-row sm:px-0 sm:leading-[3] ${isActive ? 'tab-active' : 'tab-inactive'} `}
  >
    <span className="font-mono text-sm text-primary sm:order-2">{count?.toLocaleString()}</span>
    <span className="text-xs font-semibold text-primary sm:order-1 sm:text-base">{label}</span>
  </Link>
)

export default UserSidePanel
