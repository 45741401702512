import Image from 'next/image'
import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'

import UserProfileLayout from '@common/layouts/UserProfileLayout'
import { useCurrentUserContext } from '@context/CurrentUserContext'
import { useIsDesktop } from '@hooks/useIsDesktop'
import useKeyboardShortcut from '@hooks/useKeyboardShortcut'
import { useTestGroupPublisher } from '@hooks/useTestGroupPublisher'
import { IntoNode } from '@models/IntoNode'
import { LoggedOutHero } from '@pages/home/components/LoggedOutHero'
import { setIsLoginHeroOpen } from '@redux/slices/modals/loginModalSlice'
import { useAppDispatch, useAppSelector } from '@redux/store/store'
import { DownloadAppPeekabooPanel } from '../download/DownloadAppPeekabooPanel'
import LoggedInHeader from '../header/LoggedInHeader'
import LoggedOutHeader from '../header/LoggedOutHeader'

interface LayoutProps {
  children: JSX.Element
}

const PageHeader: React.FC = () => {
  const { isLoggedIn } = useCurrentUserContext()
  const { isDisabled } = useAppSelector(state => state.header)
  const isDesktop = useIsDesktop()
  if (isDisabled) return null
  if (isLoggedIn && isDesktop) return <LoggedInHeader />
  return <LoggedOutHeader />
}

const DesktopLayout = ({ children }: LayoutProps) => {
  useTestGroupPublisher()
  const { isLoggedIn } = useCurrentUserContext()
  const dispatch = useAppDispatch()
  const {
    identifier: { slug },
  } = useAppSelector(state => state.feed)
  const isHomePage = slug === IntoNode.FEATURED.slug
  const { ref: heroRef, inView: heroRefInView } = useInView({ initialInView: true, threshold: 0.1 })
  const layoutRef = useRef<HTMLDivElement | null>(null)

  const scrollDown = () => {
    if (!heroRefInView) return
    layoutRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  useEffect(() => {
    dispatch(setIsLoginHeroOpen(heroRefInView))
  }, [heroRefInView, dispatch])

  useEffect(() => {
    dispatch(setIsLoginHeroOpen(!isLoggedIn))
  }, [dispatch, isLoggedIn])

  useKeyboardShortcut({
    codes: {
      ArrowDown: scrollDown,
    },
  })

  return (
    <div className="h-screen snap-y snap-mandatory overflow-y-scroll scrollbar-hide">
      {!isLoggedIn && isHomePage && heroRefInView && (
        <div ref={heroRef} className={`relative h-dvh snap-start col`}>
          <div className="absolute top-0 z-50 flex h-20 w-full shrink-0 items-center justify-center px-6">
            <Image src="/logo_white.svg" alt="Mix" height={40} width={120} />
          </div>
          <LoggedOutHero
            promptText="Start Exploring"
            onContinueClick={() => layoutRef.current?.scrollIntoView({ behavior: 'smooth' })}
          />
        </div>
      )}
      <div ref={layoutRef} className="h-dvh snap-start col">
        <PageHeader />
        <UserProfileLayout>{children}</UserProfileLayout>
        <DownloadAppPeekabooPanel />
      </div>
    </div>
  )
}

export default DesktopLayout
